<template>
<div v-if="adReady" class="bg-kpmg-purple py-5">
  <b-container style="min-height: 90vh" class="text-white">
    <b-row v-if="!$route.params.id">
      <b-col>
        <ol>
          <li v-for="slide in ad.slides" :key="'slide-' + slide.id" style="font-size: 2em; margin-bottom: 2rem;">
            <b-link :to="{ name: 'AppUTPresentation', params: { id: slide.id }}">{{slide.title}}</b-link>
          </li>
        </ol>
      </b-col>
    </b-row>
    <b-row class="py-4 text-white" v-if="content">
      <b-col>
        <h1>{{content.title}}</h1>
        <markdown :content="content.body" />
      </b-col>
    </b-row>
    <b-row class="py-4 text-white" v-if="content">
      <b-col>
        <b-button :to="{ name: 'AppUTPresentation', params: { id: previous.id }}">{{previous.title}}</b-button>
      </b-col>
      <b-col>
        <b-button :to="{ name: 'AppUTPresentation', params: { id: next.id }}">{{next.title}}</b-button>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import Markdown from '@/components/Markdown.vue'
export default {
  components: {
    Markdown
  },
  computed: {
    ad: function () {
      return this.$store.state.appData
    },
    adReady: function () {
      if (this.ad) {
        return true
      }
      return false
    },
    content: function () {
      if (this.$route.params.id) {
        const slide = _.find(this.ad.slides, ['id', this.$route.params.id])
        return { id: slide.id, title: slide.title, body: slide.body }
      }
      return null
    },
    next: function () {
      if (this.content) {
        const currentIndex = _.findIndex(this.ad.slides, { 'id': this.content.id })
        let nextIndex = currentIndex + 1
        if (this.ad.slides.length === nextIndex) {
          nextIndex = 0
        }
        return _.nth(this.ad.slides, nextIndex)
      }
      return null
    },
    previous: function () {
      if (this.content) {
        const currentIndex = _.findIndex(this.ad.slides, { 'id': this.content.id })
        return _.nth(this.ad.slides, currentIndex - 1)
      }
      return null
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'UT app control', action: 'open UT control' })
    console.log(this.ad.slides[1])
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
/deep/ h2 {
  margin-top: 4rem;
}

/deep/ h3 {
  margin-top: 3rem;
}

/deep/ h4 {
  margin-top: 2rem;
}
</style>
